import React from 'react'
import styled from 'styled-components'

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"

import { Container } from '../../components/global'
import Navigation from '../../components/common/navigation/navigation'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const BlogContainer = styled.div`
  display: flex;
  background-color: ${props => props.theme.color.background.light};

  .blogTitle {
    ${props => props.theme.font_size.xlarge}
    text-align: center;
    margin-bottom: 4rem;
  }
`

const PostCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-around;
  @media screen and (max-width: ${props => props.theme.screen.md}) {
    flex-flow: column nowrap;
  }
`

const PostCard = styled.article`
  flex: 0 1 45%;
  background-color: ${props => props.theme.color.background.white};
  border-radius: 16px;
  padding: .75rem 2rem;
  margin-bottom: 16px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: transform .1s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transform: scale(1.02);
    line-height: 26px;
  }
  h2 {
    ${props => props.theme.font_size.larger}
  }

  p {
    ${props => props.theme.font_size.regular}
  }
`

const Blog = ({data}) => {

  const { posts } = data.blog
  console.log(posts)
  
  return (
    <Layout>
      <SEO title="Get Django Saas Blog" description="Read tutorials on Django, Vue, Celery and everything else you need for your next SaaS project" />
      <BlogContainer>
        <Container stlye={{flex: 1}}>
          <h1 className="blogTitle" >Get Django Saas Blog</h1>

          <PostCardContainer>
            {posts.map(post => (
              <PostCard key={post.id}>
                <Link to={post.fields.slug}>
                  <Img fluid={post.frontmatter.featuredImage.childImageSharp.fluid} alt={post.frontmatter.featuredImageAlt} />
                  <h2>{post.frontmatter.title}</h2>
                  <p>{post.frontmatter.description}</p>
                </Link>
              </PostCard>
            ))}
          </PostCardContainer>
          </Container>
      </BlogContainer>
    </Layout>
  )
} 

export default Blog

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, filter: {frontmatter: {draft: {eq: false}}}) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          featuredImageAlt
          featuredImage {
            childImageSharp {
              fluid {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        excerpt
        id
      }
    }
  }
`

